// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-item {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    /* Space between items */
}

.text-year-list {
    min-width: 100px;
    /* Adjust width as needed */
    margin-right: 10px;
    text-align: right;
}

.text-title-list {
    flex-grow: 1;
}

.year-highlight {
    background-color: #FBAB1F;
    padding: 2px 5px;
    color: white;
    font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/style/List.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,wBAAwB;AAC5B;;AAEA;IACI,gBAAgB;IAChB,2BAA2B;IAC3B,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,yBAAyB;IACzB,gBAAgB;IAChB,YAAY;IACZ,iBAAiB;AACrB","sourcesContent":[".list-item {\r\n    display: flex;\r\n    align-items: center;\r\n    margin-bottom: 15px;\r\n    /* Space between items */\r\n}\r\n\r\n.text-year-list {\r\n    min-width: 100px;\r\n    /* Adjust width as needed */\r\n    margin-right: 10px;\r\n    text-align: right;\r\n}\r\n\r\n.text-title-list {\r\n    flex-grow: 1;\r\n}\r\n\r\n.year-highlight {\r\n    background-color: #FBAB1F;\r\n    padding: 2px 5px;\r\n    color: white;\r\n    font-weight: bold;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
