// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.translation-menu {
    padding-right: 15px;
}

.translation-item {
    background: none;
    border: none;
    color: #ffffff;
    padding-left: 5px;
    text-decoration: none;
    font: inherit;
}

.translation-item:hover {
    cursor: pointer;
    color: #FFD683;
}

.translation-item.active {
    color: #FBAB1F; 
}
`, "",{"version":3,"sources":["webpack://./src/style/LanguageSwitcher.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,YAAY;IACZ,cAAc;IACd,iBAAiB;IACjB,qBAAqB;IACrB,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".translation-menu {\r\n    padding-right: 15px;\r\n}\r\n\r\n.translation-item {\r\n    background: none;\r\n    border: none;\r\n    color: #ffffff;\r\n    padding-left: 5px;\r\n    text-decoration: none;\r\n    font: inherit;\r\n}\r\n\r\n.translation-item:hover {\r\n    cursor: pointer;\r\n    color: #FFD683;\r\n}\r\n\r\n.translation-item.active {\r\n    color: #FBAB1F; \r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
