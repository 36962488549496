// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `  .footer2 {
      background-color: #ffefd2;
      padding: 10px 0;
      position: fixed;
      bottom: 0;
      width: 100%;
      text-align: center;
  }

  .footer-content2 {
      display: flex;
      justify-content: center;
      align-items: center;
  }

  .footer-image2 {
      width: 100px;
      height: auto;
      margin: 0 10px;
  }`, "",{"version":3,"sources":["webpack://./src/style/Footer.css"],"names":[],"mappings":"EAAE;MACI,yBAAyB;MACzB,eAAe;MACf,eAAe;MACf,SAAS;MACT,WAAW;MACX,kBAAkB;EACtB;;EAEA;MACI,aAAa;MACb,uBAAuB;MACvB,mBAAmB;EACvB;;EAEA;MACI,YAAY;MACZ,YAAY;MACZ,cAAc;EAClB","sourcesContent":["  .footer2 {\r\n      background-color: #ffefd2;\r\n      padding: 10px 0;\r\n      position: fixed;\r\n      bottom: 0;\r\n      width: 100%;\r\n      text-align: center;\r\n  }\r\n\r\n  .footer-content2 {\r\n      display: flex;\r\n      justify-content: center;\r\n      align-items: center;\r\n  }\r\n\r\n  .footer-image2 {\r\n      width: 100px;\r\n      height: auto;\r\n      margin: 0 10px;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
