// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./FiraSans-Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Define the custom font */
@font-face {
    font-family: 'FiraSans';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
    font-weight: normal;
    font-style: normal;
}

/* Apply the custom font globally */
body {
    font-family: 'FiraSans', Arial, sans-serif; /* Use the custom font with fallbacks */
}`, "",{"version":3,"sources":["webpack://./src/style/Style.css"],"names":[],"mappings":"AAAA,2BAA2B;AAC3B;IACI,uBAAuB;IACvB,+DAAqD;IACrD,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA,mCAAmC;AACnC;IACI,0CAA0C,EAAE,uCAAuC;AACvF","sourcesContent":["/* Define the custom font */\r\n@font-face {\r\n    font-family: 'FiraSans';\r\n    src: url('./FiraSans-Regular.ttf') format('truetype');\r\n    font-weight: normal;\r\n    font-style: normal;\r\n}\r\n\r\n/* Apply the custom font globally */\r\nbody {\r\n    font-family: 'FiraSans', Arial, sans-serif; /* Use the custom font with fallbacks */\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
